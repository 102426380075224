<template>
    <Layout>

  <div>
    <form @submit.prevent="crearCarpeta">
      <div v-for="field in camposFormulario" :key="field.id" class="form-group">
        <label :for="field.id">{{ field.label }}</label>
        <input
          v-model="formData[field.key]"
          :id="field.id"
          :placeholder="field.placeholder"
          type="text"
          class="form-control"
        />
      </div>
      <button type="submit" :disabled="!formValido" class="btn btn-primary">
        {{ cargando ? 'Enviando...' : 'Enviar' }}
      </button>
    </form>

    <div v-if="responseUrl">
      <p>La carpeta ha sido creada exitosamente. Puedes verificar el estado en el siguiente enlace:</p>
      <a :href="responseUrl" target="_blank">{{ responseUrl }}</a>
    </div>
  </div>
</Layout>

</template>

<script>

import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      formData: {
        IdentificadorComplementoCartaPorte: "",
        FechaHoraOrigen: "",
        FechaHoraCertificacion: "",
        IdentificacionComplementoCartaPorte: "",
        FechaHoraSalidaLlegadaOrigen: "",
        EstatusCFDI: "",
        ConfiguracionVehicular: "",
        PlacaVM: "",
        FechaHoraOrigenDestino: "",
        PaisOrigen: "",
        TotalDistanciaRecorrida: "",
        Pais_origen: "",
        Estado_origen: "",
        Pais_destino: "",
        Estado_destino: "",
        NumeroTotal: "",
        Descripcion: "",
        Cantidad: "",
        Unidad: "",
        TipoFigura: "",
        NumeroLicencia: "",
        NumeroRegistroIdentidadTributariaFigura: "",
        ResidenciaFiscalFigura: "",
        BienesTransportados: "",
        DescripcionBienesTransportados: "",
        TransporteInternacional: "",
        RegimenAduanero: "",
        EntradaSalidaMercancia: "",
        PaisOrigenDestino: "",
        Mercancia: "",
        TotalDistanciaRecorridaMercancia: "",
        RegistroISTMO: "",
        UbicacionPoloOrigen: "",
        UbicacionPoloDestino: "",
        TipoUbicacion: "",
        RFCRemitenteDestinatario: "",
        FechaHoraSalidaLlegada: "",
        PaisUbicacion: "",
        CodigoPostal: "",
        EstadoUbicacion: "",
        Municipio: "",
        Localidad: "",
        TipoUbicacionRemitenteDestinatario: "",
        RFCRemitenteDestinatario2: "",
        FechaHoraSalidaLlegada2: "",
        DistanciaRecorrida: "",
        PaisUbicacion2: "",
        CodigoPostal2: "",
        EstadoUbicacion2: "",
        Municipio2: "",
        Localidad2: "",
        PesoBrutoTotal: "",
        UnidadPeso: "",
        PesoNetoTotal: "",
        NumeroTotalMercancias: "",
        BienesTransportados2: "",
        Descripcion2: "",
        Cantidad2: "",
        ClaveUnidad: "",
        Unidad2: "",
        MaterialPeligroso: "",
        PesoKg: "",
        TipoPermisoSCT: "",
        NumeroPermisoSCT: "",
        ConfiguracionVehicular2: "",
        PesoBrutoVehicular: "",
        PlacaVM2: "",
        AnoModeloVM: "",
        AseguradoraResponsabilidadCivil: "",
        PolizaResponsabilidadCivil: "",
        FechaHoraCertificacion_2: "",
        FechayHoradeldestino: "",
        no_pedimento: "",
        TotalDistanciaRecorridaMercancia_2: ""
      },
      cargando: false,
      responseUrl: "",
      camposFormulario: [
        // Aquí van todos los campos del formulario
        { id: 'IdentificadorComplementoCartaPorte', label: 'Identificador Complemento Carta Porte', key: 'IdentificadorComplementoCartaPorte', placeholder: 'Ej. 1234567890' },
        { id: 'FechaHoraOrigen', label: 'Fecha y Hora de Origen', key: 'FechaHoraOrigen', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'FechaHoraCertificacion', label: 'Fecha y Hora de Certificación', key: 'FechaHoraCertificacion', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'IdentificacionComplementoCartaPorte', label: 'Identificación Complemento Carta Porte', key: 'IdentificacionComplementoCartaPorte', placeholder: 'Ej. ID123456' },
        { id: 'FechaHoraSalidaLlegadaOrigen', label: 'Fecha y Hora de Salida/Llegada Origen', key: 'FechaHoraSalidaLlegadaOrigen', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'EstatusCFDI', label: 'Estatus CFDI', key: 'EstatusCFDI', placeholder: 'Ej. Válido' },
        { id: 'ConfiguracionVehicular', label: 'Configuración Vehicular', key: 'ConfiguracionVehicular', placeholder: 'Ej. 3EJ2' },
        { id: 'PlacaVM', label: 'Placa del Vehículo Motorizado', key: 'PlacaVM', placeholder: 'Ej. ABC123' },
        { id: 'FechaHoraOrigenDestino', label: 'Fecha y Hora de Origen/Destino', key: 'FechaHoraOrigenDestino', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'PaisOrigen', label: 'País de Origen', key: 'PaisOrigen', placeholder: 'Ej. México' },
        { id: 'TotalDistanciaRecorrida', label: 'Total Distancia Recorrida', key: 'TotalDistanciaRecorrida', placeholder: 'Ej. 500 km' },
        { id: 'Pais_origen', label: 'País de Origen (duplicado)', key: 'Pais_origen', placeholder: 'Ej. México' },
        { id: 'Estado_origen', label: 'Estado de Origen', key: 'Estado_origen', placeholder: 'Ej. Chihuahua' },
        { id: 'Pais_destino', label: 'País de Destino', key: 'Pais_destino', placeholder: 'Ej. México' },
        { id: 'Estado_destino', label: 'Estado de Destino', key: 'Estado_destino', placeholder: 'Ej. Ciudad de México' },
        { id: 'NumeroTotal', label: 'Número Total', key: 'NumeroTotal', placeholder: 'Ej. 10' },
        { id: 'Descripcion', label: 'Descripción', key: 'Descripcion', placeholder: 'Ej. Mercancía variada' },
        { id: 'Cantidad', label: 'Cantidad', key: 'Cantidad', placeholder: 'Ej. 100' },
        { id: 'Unidad', label: 'Unidad', key: 'Unidad', placeholder: 'Ej. kg' },
        { id: 'TipoFigura', label: 'Tipo de Figura', key: 'TipoFigura', placeholder: 'Ej. Transportista' },
        { id: 'NumeroLicencia', label: 'Número de Licencia', key: 'NumeroLicencia', placeholder: 'Ej. LIC1234' },
        { id: 'NumeroRegistroIdentidadTributariaFigura', label: 'Número de Registro Identidad Tributaria Figura', key: 'NumeroRegistroIdentidadTributariaFigura', placeholder: 'Ej. RIT123456' },
        { id: 'ResidenciaFiscalFigura', label: 'Residencia Fiscal Figura', key: 'ResidenciaFiscalFigura', placeholder: 'Ej. México' },
        { id: 'BienesTransportados', label: 'Bienes Transportados', key: 'BienesTransportados', placeholder: 'Ej. Electrodomésticos' },
        { id: 'DescripcionBienesTransportados', label: 'Descripción de Bienes Transportados', key: 'DescripcionBienesTransportados', placeholder: 'Ej. Televisores, refrigeradores' },
        { id: 'TransporteInternacional', label: 'Transporte Internacional', key: 'TransporteInternacional', placeholder: 'Ej. No' },
        { id: 'RegimenAduanero', label: 'Régimen Aduanero', key: 'RegimenAduanero', placeholder: 'Ej. Régimen General' },
        { id: 'EntradaSalidaMercancia', label: 'Entrada/Salida Mercancía', key: 'EntradaSalidaMercancia', placeholder: 'Ej. Entrada' },
        { id: 'PaisOrigenDestino', label: 'País Origen/Destino', key: 'PaisOrigenDestino', placeholder: 'Ej. México' },
        { id: 'Mercancia', label: 'Mercancía', key: 'Mercancia', placeholder: 'Ej. General' },
        { id: 'TotalDistanciaRecorridaMercancia', label: 'Total Distancia Recorrida Mercancía', key: 'TotalDistanciaRecorridaMercancia', placeholder: 'Ej. 200 km' },
        { id: 'RegistroISTMO', label: 'Registro ISTMO', key: 'RegistroISTMO', placeholder: 'Ej. IST12345' },
        { id: 'UbicacionPoloOrigen', label: 'Ubicación Polo Origen', key: 'UbicacionPoloOrigen', placeholder: 'Ej. CDMX' },
        { id: 'UbicacionPoloDestino', label: 'Ubicación Polo Destino', key: 'UbicacionPoloDestino', placeholder: 'Ej. Puebla' },
        { id: 'TipoUbicacion', label: 'Tipo de Ubicación', key: 'TipoUbicacion', placeholder: 'Ej. Almacén' },
        { id: 'RFCRemitenteDestinatario', label: 'RFC Remitente/Destinatario', key: 'RFCRemitenteDestinatario', placeholder: 'Ej. RFC123456' },
        { id: 'FechaHoraSalidaLlegada', label: 'Fecha y Hora de Salida/Llegada', key: 'FechaHoraSalidaLlegada', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'PaisUbicacion', label: 'País de Ubicación', key: 'PaisUbicacion', placeholder: 'Ej. México' },
        { id: 'CodigoPostal', label: 'Código Postal', key: 'CodigoPostal', placeholder: 'Ej. 01000' },
        { id: 'EstadoUbicacion', label: 'Estado de Ubicación', key: 'EstadoUbicacion', placeholder: 'Ej. CDMX' },
        { id: 'Municipio', label: 'Municipio', key: 'Municipio', placeholder: 'Ej. Cuauhtémoc' },
        { id: 'Localidad', label: 'Localidad', key: 'Localidad', placeholder: 'Ej. Centro' },
        { id: 'TipoUbicacionRemitenteDestinatario', label: 'Tipo de Ubicación Remitente/Destinatario', key: 'TipoUbicacionRemitenteDestinatario', placeholder: 'Ej. Sucursal' },
        { id: 'RFCRemitenteDestinatario2', label: 'RFC Remitente/Destinatario 2', key: 'RFCRemitenteDestinatario2', placeholder: 'Ej. RFC654321' },
        { id: 'FechaHoraSalidaLlegada2', label: 'Fecha y Hora de Salida/Llegada 2', key: 'FechaHoraSalidaLlegada2', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'DistanciaRecorrida', label: 'Distancia Recorrida', key: 'DistanciaRecorrida', placeholder: 'Ej. 250 km' },
        { id: 'PaisUbicacion2', label: 'País de Ubicación 2', key: 'PaisUbicacion2', placeholder: 'Ej. México' },
        { id: 'CodigoPostal2', label: 'Código Postal 2', key: 'CodigoPostal2', placeholder: 'Ej. 02000' },
        { id: 'EstadoUbicacion2', label: 'Estado de Ubicación 2', key: 'EstadoUbicacion2', placeholder: 'Ej. CDMX' },
        { id: 'Municipio2', label: 'Municipio 2', key: 'Municipio2', placeholder: 'Ej. Benito Juárez' },
        { id: 'Localidad2', label: 'Localidad 2', key: 'Localidad2', placeholder: 'Ej. Sur' },
        { id: 'PesoBrutoTotal', label: 'Peso Bruto Total', key: 'PesoBrutoTotal', placeholder: 'Ej. 2000 kg' },
        { id: 'UnidadPeso', label: 'Unidad de Peso', key: 'UnidadPeso', placeholder: 'Ej. kg' },
        { id: 'PesoNetoTotal', label: 'Peso Neto Total', key: 'PesoNetoTotal', placeholder: 'Ej. 1800 kg' },
        { id: 'NumeroTotalMercancias', label: 'Número Total de Mercancías', key: 'NumeroTotalMercancias', placeholder: 'Ej. 5' },
        { id: 'BienesTransportados2', label: 'Bienes Transportados 2', key: 'BienesTransportados2', placeholder: 'Ej. Electrodomésticos' },
        { id: 'Descripcion2', label: 'Descripción 2', key: 'Descripcion2', placeholder: 'Ej. Microondas, lavadoras' },
        { id: 'Cantidad2', label: 'Cantidad 2', key: 'Cantidad2', placeholder: 'Ej. 50' },
        { id: 'ClaveUnidad', label: 'Clave Unidad', key: 'ClaveUnidad', placeholder: 'Ej. kg' },
        { id: 'Unidad2', label: 'Unidad 2', key: 'Unidad2', placeholder: 'Ej. kg' },
        { id: 'MaterialPeligroso', label: 'Material Peligroso', key: 'MaterialPeligroso', placeholder: 'Ej. No' },
        { id: 'PesoKg', label: 'Peso Kg', key: 'PesoKg', placeholder: 'Ej. 500 kg' },
        { id: 'TipoPermisoSCT', label: 'Tipo de Permiso SCT', key: 'TipoPermisoSCT', placeholder: 'Ej. Permiso SCT' },
        { id: 'NumeroPermisoSCT', label: 'Número de Permiso SCT', key: 'NumeroPermisoSCT', placeholder: 'Ej. SCT1234' },
        { id: 'ConfiguracionVehicular2', label: 'Configuración Vehicular 2', key: 'ConfiguracionVehicular2', placeholder: 'Ej. 4EJ3' },
        { id: 'PesoBrutoVehicular', label: 'Peso Bruto Vehicular', key: 'PesoBrutoVehicular', placeholder: 'Ej. 2500 kg' },
        { id: 'PlacaVM2', label: 'Placa del Vehículo Motorizado 2', key: 'PlacaVM2', placeholder: 'Ej. XYZ789' },
        { id: 'AnoModeloVM', label: 'Año Modelo del Vehículo Motorizado', key: 'AnoModeloVM', placeholder: 'Ej. 2023' },
        { id: 'AseguradoraResponsabilidadCivil', label: 'Aseguradora de Responsabilidad Civil', key: 'AseguradoraResponsabilidadCivil', placeholder: 'Ej. Aseguradora XYZ' },
        { id: 'PolizaResponsabilidadCivil', label: 'Póliza de Responsabilidad Civil', key: 'PolizaResponsabilidadCivil', placeholder: 'Ej. POL12345' },
        { id: 'FechaHoraCertificacion_2', label: 'Fecha y Hora de Certificación 2', key: 'FechaHoraCertificacion_2', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'FechayHoradeldestino', label: 'Fecha y Hora del Destino', key: 'FechayHoradeldestino', placeholder: 'YYYY-MM-DDTHH:MM:SS' },
        { id: 'no_pedimento', label: 'Número de Pedimento', key: 'no_pedimento', placeholder: 'Ej. PED123456' },
        { id: 'TotalDistanciaRecorridaMercancia_2', label: 'Total Distancia Recorrida Mercancía 2', key: 'TotalDistanciaRecorridaMercancia_2', placeholder: 'Ej. 300 km' }
      ]
    };
  },
  computed: {
    formValido() {
      return Object.values(this.formData).every(value => value.trim() !== '');
    }
  },
  methods: {
    async crearCarpeta() {
      this.cargando = true;
      try {
        const response = await fetch('https://verificacfdi.facturaelectronica.siatmat.mx/verificaccp/api_verifica/create.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.formData)
        });

        const data = await response.json();

        console.log(data)
        
        if (data.exito === 1) {
          const { IdentificadorComplementoCartaPorte, FechaHoraOrigen, FechaHoraCertificacion } = this.formData;
          this.responseUrl = `https://verificacfdi.facturaelectronica.siatmat.mx/verificaccp/default.aspx/?idCCP=${IdentificadorComplementoCartaPorte}&fechaOrigen=${FechaHoraOrigen}&fechaCertificacion=${FechaHoraCertificacion}`;
          alert("El enlace ha sido creado exitosamente.");
        } else {
          alert("Error al crear el enlace. Por favor, inténtelo de nuevo.");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Error al crear el enlace. Por favor, inténtelo de nuevo.");
      } finally {
        this.cargando = false; // Ocultar cargando
      }
    }
  }
};
</script>

<style scoped>
/* Agrega estilos si es necesario */
</style>
